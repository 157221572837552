@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HurmeGeometricSans2";
   src: url("static/fonts/HurmeGeometricSans2.otf") format("truetype");
}

@font-face {
  font-family: "HurmeGeometricSans2 light";
  src: url("static/fonts/HurmeGeometricSans2 Light.otf") format("truetype");
}


html,
body {
  font-family: "HurmeGeometricSans2";
}

body {
  margin: 0;
  font-family: "HurmeGeometricSans2";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
